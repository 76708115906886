<template>
  <div class="flex flex-col">
    <div
      class="flex flex-col items-start self-center w-11/12 mt-8 bg-yellow p-4"
      v-if="stats"
    >
      <h2 class="text-2xl font-bold">Usuarios</h2>
      <div class="grid grid-cols-5 gap-4 w-full justify-items-center">
        <p class="mb-0 justify-self-start">
          <span class="font-bold">Usuarios registrados:</span>
          {{ stats["userCount"] }}
        </p>
        <p class="mb-0">
          <span class="font-bold">Usuarios dueños:</span>
          {{ stats["ownerCount"] }}
        </p>
        <p class="mb-0">
          <span class="font-bold">Usuarios dependientes:</span>
          {{ stats["workerCount"] }}
        </p>
      </div>

      <h2 class="text-2xl font-bold mt-8 mb-2">Trivias</h2>
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Pedigree</h3>
          <p class="mb-0">
            <span class="font-bold">Cachorros por etapas:</span>
            {{ stats["ped-01"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Cachorros alimentación:</span>
            {{ stats["ped-02"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Cachorros composición nutricional:</span>
            {{ stats["ped-03"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Razas pequeñas por etapas:</span>
            {{ stats["ped-04"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Razas pequeñas alimentación:</span>
            {{ stats["ped-05"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Razas pequeñas composición nutricional:</span
            >
            {{ stats["ped-06"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Razas grandes y senior por etapas:</span>
            {{ stats["ped-07"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Razas grandes y senior alimentación:</span>
            {{ stats["ped-08"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Razas grandes y senior composición nutricional:</span
            >
            {{ stats["ped-09"] }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Dentastix</h3>
          <p class="mb-0">
            <span class="font-bold">Beneficios por etapas:</span>
            {{ stats["den-01"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Guía de alimentación:</span>
            {{ stats["den-02"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Composición nutricional:</span>
            {{ stats["den-03"] }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Whiskas</h3>
          <p class="mb-0">
            <span class="font-bold">Gatitos por etapas:</span>
            {{ stats["whi-01"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatitos alimentación:</span>
            {{ stats["whi-02"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatitos composición nutricional:</span>
            {{ stats["whi-03"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatos por etapas:</span>
            {{ stats["whi-04"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatos alimentación:</span>
            {{ stats["whi-05"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatos composición nutricional:</span>
            {{ stats["whi-06"] }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Sheba</h3>
          <p class="mb-0">
            <span class="font-bold">Gatitos por etapas:</span>
            {{ stats["she-01"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatitos alimentación:</span>
            {{ stats["she-02"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatitos composición nutricional:</span>
            {{ stats["she-03"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatos por etapas:</span>
            {{ stats["she-04"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatos alimentación:</span>
            {{ stats["she-05"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatos composición nutricional:</span>
            {{ stats["she-06"] }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Kitekat</h3>
          <p class="mb-0">
            <span class="font-bold">Beneficios por etapas:</span>
            {{ stats["kit-01"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Guía de alimentación:</span>
            {{ stats["kit-02"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Composición nutricional:</span>
            {{ stats["kit-03"] }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Optimum Perros</h3>
          <p class="mb-0">
            <span class="font-bold">Cachorro - Beneficios por etapas:</span>
            {{ stats["opt-01"] ? stats["opt-01"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Cachorro - Guía de alimentación:</span>
            {{ stats["opt-02"] ? stats["opt-02"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Cachorro - Composición nutricional:</span>
            {{ stats["opt-03"] ? stats["opt-03"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Razas pequeñas - Beneficios por etapas:</span
            >
            {{ stats["opt-04"] ? stats["opt-04"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Razas pequeñas - Guía de alimentación:</span
            >
            {{ stats["opt-05"] ? stats["opt-05"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Razas pequeñas - Composición nutricional:</span
            >
            {{ stats["opt-06"] ? stats["opt-06"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Adultos - Beneficios por etapas:</span>
            {{ stats["opt-07"] ? stats["opt-07"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Adultos - Guía de alimentación:</span>
            {{ stats["opt-08"] ? stats["opt-08"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Adultos - Composición nutricional:</span>
            {{ stats["opt-09"] ? stats["opt-09"] : 0 }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Optimum Gatos</h3>
          <p class="mb-0">
            <span class="font-bold">Gatitos - Beneficios por etapas:</span>
            {{ stats["opt-10"] ? stats["opt-10"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatitos - Guía de alimentación:</span>
            {{ stats["opt-11"] ? stats["opt-11"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Gatitos - Composición nutricional:</span>
            {{ stats["opt-12"] ? stats["opt-12"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Adultos - Beneficios por etapas:</span>
            {{ stats["opt-13"] ? stats["opt-13"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Adultos - Guía de alimentación:</span>
            {{ stats["opt-14"] ? stats["opt-14"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Adultos - Composición nutricional:</span>
            {{ stats["opt-15"] ? stats["opt-15"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Castrados - Beneficios por etapas:</span>
            {{ stats["opt-16"] ? stats["opt-16"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Castrados - Guía de alimentación:</span>
            {{ stats["opt-17"] ? stats["opt-17"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Castrados - Composición nutricional:</span>
            {{ stats["opt-18"] ? stats["opt-18"] : 0 }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Temptations</h3>
          <p class="mb-0">
            <span class="font-bold">Trivia lanzamiento:</span>
            {{ stats["temp-01"] ? stats["temp-01"] : 0 }}
          </p>
        </div>
        <div class="flex flex-col items-start">
          <h3 class="text-lg font-bold">Artículos</h3>
          <p class="mb-0">
            <span class="font-bold">Prevención de enfermedad periodontal:</span>
            {{ stats["art-01"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Manejo del cuidado oral de una mascota:</span
            >
            {{ stats["art-02"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >5 tips curiosos que no sabías de tu mascota perruna:</span
            >
            {{ stats["art-03"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >¿Por qué implementar un programa de prevención urinaria?:</span
            >
            {{ stats["art-04"] ? stats["art-04"] : 0 }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Alimentos prohibidos para perros y gatos:</span
            >
            {{ stats["art-05"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Conceptos básicos de nutrición para perros y gatos:</span
            >
            {{ stats["art-06"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold"
              >Macro y micronutrientes para perros y gatos:</span
            >
            {{ stats["art-07"] }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Mix feeding:</span> {{ stats["art-08"] }}
          </p>
        </div>
      </div>
    </div>
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "../../components/Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      isLoading: true,
      stats: null,
    };
  },
  async mounted() {
    const res = await this["stats/getStats"]();
    if (res.success) {
      this.stats = res.stats;
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["stats/getStats"]),
  },
};
</script>
